/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import HoldingLayout from "../components/holding-layout"

export const pageQuery = graphql`
  query HoldingQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        tagline
      }
    }
  }
`

const HoldingPage = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  return (
    <HoldingLayout>
      <SEO />
      {/* <div className="grids col-1 sm-2"> */}

      <div className="holding-page">
        <div className="circle">
          <h1 className="circle__text">{frontmatter.title}</h1>
        </div>
        {/* <div className="intro-text">
          <p>{frontmatter.tagline}</p>
        </div> */}
        <div className="sub-text">
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
    </HoldingLayout>
  )
}

export default HoldingPage
