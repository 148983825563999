/** @jsx jsx */
import { jsx } from "theme-ui"

import "../assets/scss/style.scss"

const HoldingLayout = ({ children }) => {
  return <main>{children}</main>
}

export default HoldingLayout
